<template>
  <div class="ManagerAllDevis">


    <!-- SET COLLABORATEUR ALERT -->
    <CModal
      :show.sync="isCreatingDevis"
      :no-close-on-backdrop="true"
      title="Créer un nouveau devis"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          <vue-bootstrap-typeahead
            v-model="addDevisClientName"
            :data="addDevisClients"
            :serializer="item => item.name"
            @hit="addDevisClientObject = $event"
             placeholder="Chercher votre client par nom"
          />
        </CCol>
      </CRow>


      <CButton
        color="outline-primary"
        shape="pill" block class="px-4 mt-4"
        @click="$router.push('/add-new-devis/'+addDevisClientObject.id)"
        :disabled="$v.addDevisClientObject.$invalid">
        Créer un devis
      </CButton>


      <template #footer>
        <CButton @click="isCreatingDevis = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol>
        <h1> Devis </h1>
      </CCol>

      <CCol class="text-right align-self-center">
        <CButton
          @click="isCreatingDevis = true"
          size="sm"
          shape="pill"
          color="success">
            <CIcon name="cil-plus"/> Créer un nouveau devis
        </CButton>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol xl="5">
            <CInput
              label="Date de début"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="startDateFilter">
            </CInput>
          </CCol>
          <CCol xl="5">
            <CInput
              label="Date de fin"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="endDateFilter">
            </CInput>
          </CCol>
          <CCol xl="2">
            <CButton
              color="primary"
              variant="outline"
              shape="pill"
              block
              @click="getAllDevisForManager">
            Filtrer par date
          </CButton>
          </CCol>
        </CRow>

        <CRow class="mt-3 mt-xl-0">
          <CCol>
            <a class="eclerk-link" href="#" @click="reinitializeWeek">
              Depuis le début de la semaine
            </a>
            <br class="d-sm-block d-md-none">

            <a class="ml-0 ml-md-2 eclerk-link" href="#" @click="reinitializeMonth">
              Depuis le début du mois
            </a>
          </CCol>
        </CRow>

        <CRow class="mt-3">

          <CCol sm="4">
            <CInputCheckbox
              label="Tous les clients"
              :checked.sync="isAllClientsFilter"
              :custom="true"
            >
            </CInputCheckbox>
          </CCol>
          <CCol v-if="!isAllClientsFilter" sm="8" class="mt-2 mt-sm-0">
            <vue-bootstrap-typeahead
              v-model="clientFilterName"
              :data="filterClients"
              :serializer="item => item.name"
              @hit="clientFilterObject = $event"
               placeholder="Chercher votre client par nom"
            />

          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

    <CRow style="font-size: 1.2rem;">
      <CCol>
        <strong class="text-primary" style="font-size: 1.6rem;">{{nbDevis}}</strong> devis
      </CCol>
    </CRow>

    <CRow class="mt-4">
      <CCol xl="6">
        <CWidgetIcon
          text="montant total HT"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalHT).toFixed(0))) + ' €'}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="6">
        <CWidgetIcon
          :header="String()"
          text="montant total TTC"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalTTC).toFixed(0))) + ' €'}}</strong>
        </CWidgetIcon>
      </CCol>
    </CRow>


    <CRow>
      <CCol>
        <CCard >
          <CCardBody>
            <CRow>
              <CCol>
                <CDataTable
                  class="cursor-table"
                  :items-per-page="50"
                  sorter
                  pagination
                  :items="devisRender"
                  :fields="devisRenderFields"
                  @row-clicked="goToDevisPage"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >
                <template #over-table>
                  <CRow class="mt-2">
                    <CCol sm="6">
                      <exportTableDataComponent
                        v-if="chosenDevis.length > 0"
                        v-bind:fields="devisFileRenderFields"
                        v-bind:data="chosenDevis"
                        name="Eclerk - Devis"
                        label="Exporter la sélection"
                      />
                    </CCol>
                    <CCol sm="6" class="text-right">
                      <exportTableDataComponent
                        v-if="devisRender.length > 0"
                        v-bind:data="devisRender"
                        v-bind:fields="devisFileRenderFields"
                        name="Eclerk - Devis.csv"
                        label="Exporter la liste"
                      />
                    </CCol>
                  </CRow>
                </template>
                <template #actions="{item}">
                  <td @click.stop>
                    <CInputCheckbox
                      :custom="true"
                      :inline="true"
                      @update:checked="checkDevisBox(item)"
                    />
                  </td>

                </template>
                <template #client="{item}">
                  <td>
                   <nobr><CIcon v-c-tooltip="{content: 'Envoyé au client',placement: 'right'}" v-if="item.has_been_sent" name="cil-send" /> {{item.client}}</nobr>
                  </td>
                </template>
                <template #date="{item}">
                  <td>
                   <nobr><span>{{$dayjs(item.date).format('DD/MM/YYYY')}}</span></nobr>
                  </td>
                </template>
                <template #montant_total_ht="{item}">
                  <td>
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant_total_ht).toFixed(2))}} €</nobr>
                  </td>
                </template>
                <template #montant_total_ttc="{item}">
                  <td>
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant_total_ttc).toFixed(2))}} €</nobr>
                  </td>
                </template>

                <template #no-items-view>
                  <CRow>
                    <CCol class="text-center">
                      Aucun devis
                    </CCol>
                  </CRow>
                </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, integer} from 'vuelidate/lib/validators'

import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'

import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'ManagerAllDevis',
  components: {
    Loading,
    VueBootstrapTypeahead,
    exportTableDataComponent
  },
  mixins: [
    validationMixin,
    renderMixins,
    userMixins
  ],
  data: function () {
    var today = this.$dayjs()
    var oneMonthAgo = today.subtract('3', 'months')
    return {

      // ------------- ADD FACTURE -----------
      isCreatingDevis: false,

      addDevisClients: [],
      addDevisClientName: '',
      addDevisClientObject: {
        id: '',
        name: ''
      },

      // ------------- FACTURE -----------
      startDateFilter: oneMonthAgo.format('YYYY-MM-DD'),
      endDateFilter: today.format('YYYY-MM-DD'),

      isAllClientsFilter: true,
      filterClients: [],
      clientFilterName: '',
      clientFilterObject: {
        id: '',
        name: ''
      },

      devis: [],
      devisRender: [],
      devisRenderFields: [
        { key: "actions", label: ''},
        { key: "number", label: "#"},
        { key: "exercice_comptable", label: 'Exercice'},
        { key: "client", label: "Client"},
        { key: "intitule", label: "Intitulé"},
        { key: "date", label: "Date", sortable: true },
        { key: "montant_total_ht", label: "Montant total HT", sortable: true },
        { key: "montant_total_ttc", label: "Montant total TTC", sortable: true },
      ],
      devisFileRenderFields: {
        '#': 'number',
        'Client': 'client',
        'Libellé': 'intitule',
        'Date': 'date',
        'Montant total HT': 'montant_total_ht',
        'Montant total TTC': 'montant_total_ttc'
      },

      isAllManagerDevisDashboardLoading: false,

      nbDevis: 0,
      montantTotalHT: 0,
      montantTotalTTC: 0,

      // ------------- CLIENTS -----------
      clients: [],
      clientsSelectOptions: [],
      isAllClientsLoading: false,

      // ------------- DOWNLOAD FACTURES -------
      chosenDevis: []

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllManagerDevisDashboardLoading || this.isAllClientsLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllDevisForManager()
  },

  validations: {
    addDevisClientObject: {
      id: {
        required,
        minValue: minValue(1),
        integer
      }
    },
  },

  watch: {

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    addDevisClientName(newQuery) {
      if (newQuery == '') {
        this.addDevisClients = []
        this.addDevisClientObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.addDevisClients = res.data
        })
      }
    },

    clientFilterName(newQuery) {
      if (newQuery == '') {
        this.filterClients = []
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.filterClients = res.data
        })
      }
    },

    clientFilterObject(newObject) {
      if (newObject.id === parseInt(newObject.id, 10)) {
        if (newObject.id >= 1) {
          this.getAllDevisForManager()
        }
      }
      else if (newObject.id == '') {
        this.getAllDevisForManager()
      }
    },

    isAllClientsFilter(newBoolean) {
      if (newBoolean) {
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
    },

    devis: function (newDevis) {

      // PAIEMENT STATISTIQUES
      this.nbDevis = 0
      this.montantTotalHT = 0
      this.montantTotalTTC = 0

      // PAIEMENT DETAILLED
      this.devisRender = []

      for (var i = 0; i < newDevis.length; i++) {
        var temp_data = {
          'id': newDevis[i].id,
          'number': newDevis[i].number,
          'exercice_comptable': newDevis[i].exercice_comptable,
          'client': newDevis[i].client_name,
          'intitule': newDevis[i].title,
          'date': newDevis[i].date,
          'montant_total_ht': parseFloat(newDevis[i].montant_total_ht) - parseFloat(newDevis[i].remise_totale_ht),
          'montant_total_ttc': newDevis[i].montant_total_ttc,
          'has_been_sent': newDevis[i].has_been_sent
        }

        this.montantTotalHT = parseFloat(this.montantTotalHT) + parseFloat(newDevis[i].montant_total_ht) - parseFloat(newDevis[i].remise_totale_ht)
        this.montantTotalTTC = parseFloat(this.montantTotalTTC) + parseFloat(newDevis[i].montant_total_ttc)
        this.nbDevis ++
        this.devisRender.push(temp_data)
      }
    },
  },
  methods: {
    // -------------- GETTERS -----------------------
    getAllDevisForManager () {
      this.isAllManagerDevisDashboardLoading = true
      apiManagerConnected.getAllDevisForManager(this.token, this.startDateFilter, this.endDateFilter, this.clientFilterObject.id)
      .then((result) => {
        this.devis = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllManagerDevisDashboardLoading = false
      })
    },

    // ------------ Filter functions ----------------
    reinitializeWeek () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('week').format('YYYY-MM-DD')
      this.getAllDevisForManager()
    },
    reinitializeMonth () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('month').format('YYYY-MM-DD')
      this.getAllDevisForManager()
    },

    checkDevisBox (item) {
      const index = this.chosenDevis.indexOf(item)
      if (index === -1){
        this.chosenDevis.push(item)
      }
      else {
        this.chosenDevis.splice(index, 1)
      }
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToDevisPage(item) {
      var routeData = this.$router.resolve('modify-devis/' + item.id);
      window.open(routeData.href, '_blank');
    }


  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.thead-clickable {
  cursor: pointer;
}

.thead-clickable:hover{
  color: black !important;
}

</style>
